import React from 'react';

const listItem = (value, index) => (
  <li key={`list-item-${index}`}>{value}</li>
);

export const createList = (listData, index) => {
  const { heading, listType, items } = listData;
  const listItems = items.map(listItem);

  const list = listType === 'unordered'
    ? <ul className="list">{listItems}</ul>
    : <ol className="list">{listItems}</ol>;

  return (
    <div className="list-name" key={`list-${index}`}>
      {heading && <h4 className="list-heading">{heading}</h4>}
      {list}
    </div>
  );
};
