import React from 'react';
import PropTypes from 'prop-types';
import { createList } from './list';
import './List.css';

export const listPropType = PropTypes.shape({
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  listType: PropTypes.string,
});

const List = ({ list }) => {
  let content = null;
  if (Array.isArray(list)) {
    const listOfLists = list.map(createList);
    content = <div>{listOfLists}</div>;
  } else {
    content = createList(list, 1);
  }
  return content;
};

List.propTypes = {
  list: PropTypes.oneOfType([
    listPropType,
    PropTypes.arrayOf(listPropType),
  ]),
};

export default List;
