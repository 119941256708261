import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// State that we aren't doing anything with yet.
const siteTitle = (state = '', action) => state;
const pages = (state = [], action) => state;
const copyright = (state = '', action) => state;
const social = (state = [], action) => state;
const home = (state = {}, action) => state;
const notFound = (state = {}, action) => state;

// UI state issues
const windowScrollPosition = (state = 0, action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    return 0;
  }

  if (action.type === 'SCROLLING_DOWN' || action.type === 'SCROLLING_UP') {
    return null;
  }

  return state;
};

const scrollState = (state = 'top', action) => {
  if (action.type.startsWith('SCROLLING_')) {
    return action.type.replace('SCROLLING_', '').toLowerCase();
  }

  return state;
};

const isNavOpen = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_NAV':
      return !state;
    case 'SCROLLING_DOWN':
    case '@@router/LOCATION_CHANGE':
      return false;
    default:
      return state;
  }
};

const mode = (state = '', action) => {
  const { type } = action;
  switch (type) {
    case 'MODE_CHANGING':
      return 'transition' + (state !== '' ? ' ' : '') + state;
    case 'MODE_TOGGLE':
      if (state.includes('night')) {
        return state.includes('transition') ? 'transition' : '';
      } else {
        return state.includes('transition') ? 'transition night' : 'transition';
      }
    case 'MODE_CHANGE_COMPLETE':
      return state.includes('night') ? 'night' : '';
    default:
      return state;
  }
};

export const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  home,
  mode,
  isNavOpen,
  windowScrollPosition,
  copyright,
  scrollState,
  siteTitle,
  pages,
  social,
  notFound,
});
