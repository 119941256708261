import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  AppBar,
  AppFooter,
  ModeTransition,
} from '../';
import './App.css';

const App = ({ children, mode, id }) => (
  <div className={`primary-container ${mode}`} id={id}>
    <ModeTransition />
    <AppBar />
    <main>
      {children}
    </main>
    <AppFooter />
  </div>
);

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  mode: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default withRouter(connect((store) => ({
  mode: store.mode,
}))(App));
