import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'react-mdl';
import { Link } from 'react-router-dom';
import './Action.css';

const Action = ({ data }) => {
  if (typeof data === 'undefined') {
    return null;
  }

  const { caption, url } = data;
  let action;
  if (url.startsWith('http') || url.startsWith('//')) {
    action = (
      <span className='button-wrapper'>
        <Button
          className='action with-icon'
          href={url}
          raised
          rel="noopener noreferrer"
          ripple
          target="_blank"
        >
          <Icon name="open_in_new" />
          <span>{caption}</span>
        </Button>
      </span>
    );
  } else if (url.startsWith('mailto:')) {
    action = (
      <span className='button-wrapper'>
        <Button href={url} className='action with-icon' raised ripple>
          <Icon name="mail_outline" />
          <span>{caption}</span>
        </Button>
      </span>
    );
  } else if (url.startsWith('tel:')) {
    action = (
      <span className='button-wrapper'>
        <Button href={url} className='action with-icon' raised ripple>
          <Icon name="phone" />
          <span>{caption}</span>
        </Button>
      </span>
    );
  } else {
    action = (
      <Link to={url} className='action'>
        <Button ripple>
          {caption}
        </Button>
      </Link>
    );
  }

  return action;
};

Action.propTypes = {
  data: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default Action;
