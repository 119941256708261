import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FooterLinkList } from 'react-mdl';
import _ from 'lodash';
import { kebab } from '../../routes';

const links = ({ pages, footer, menu }) => {
  const links = [];
  for (const key in pages) {
    if (typeof (pages[key]) !== 'undefined') {
      const pageMenu = pages[key].menu;
      if (key !== 'home' && _.includes(pageMenu, menu)) {
        links.push(
          <NavLink
            activeClassName="active"
            key={key}
            to={kebab(key)}
          >
            {key}
          </NavLink>
        );
      }
    }
  }

  return footer
    ? <FooterLinkList>{links}</FooterLinkList>
    : <nav>{links}</nav>;
};

links.propTypes = {
  footer: PropTypes.bool,
  menu: PropTypes.string,
  pages: PropTypes.object,
};

export default withRouter(connect((state) => ({
  pages: state.pages,
}))(links));
