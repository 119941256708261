import React from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { pageFactory } from '../components';
import data from '../database/en-us.json';

export const kebab = (url) => `/${_.kebabCase(url)}`;

const buildRoutes = (pages, parent) => _.map(pages, (page, key) => {
  const keb = kebab(key);
  const url = parent ? `${parent}${keb}` : keb;
  const routes = [
    <Route
      component={pageFactory(page)}
      key={url}
      path={url}
      exact
    />,
  ];
  return routes.concat(buildRoutes(page.children, url));
});

const Routes = () => (
  <Switch>
    <Route
      component={pageFactory(data.home)}
      exact
      path="/"
    />
    {buildRoutes(data.pages)}
    <Route component={pageFactory(data.notFound)} />
  </Switch>
);

export default Routes;
