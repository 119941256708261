import React from 'react';
import PropTypes from 'prop-types';
import { Spacer } from 'react-mdl';
import { Action } from '../';
import './CallToAction.css';

const CallToAction = ({ data }) => {
  if (typeof data === 'undefined') {
    return null;
  }

  const { caption, action, avatar } = data;
  let avatarImg;
  let content;
  if (avatar) {
    const { name, imageUrl } = avatar;
    avatarImg = (
      <img alt="" src={imageUrl} className="avatar" />
    );
    content = (
      <h4 className="caption with-author">
        {caption}
        <cite>- {name}</cite>
      </h4>
    );
  } else {
    content = <h3 className="caption">{caption}</h3>;
  }

  return (
    <div className="call-to-action">
      <div className="section-container">
        {avatarImg}
        {content}
        <Spacer />
        <Action data={action} />
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  data: PropTypes.shape({
    action: PropTypes.shape({
      caption: PropTypes.string,
      url: PropTypes.string,
    }),
    avatar: PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
    caption: PropTypes.string,
  }),
};

export default CallToAction;
