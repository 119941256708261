import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Spacer, Button, IconButton, Tooltip } from 'react-mdl';
import { Link, withRouter } from 'react-router-dom';
import { Links } from '../';
import './AppBar.css';

const MODE = 'night';

const AppBar = ({
  dispatch,
  siteTitle,
  scrollState,
  isNavOpen,
  mode,
}) => {
  const toggleNav = (event) => {
    if (event) {
      event.preventDefault();
    }

    dispatch({ type: 'TOGGLE_NAV' });
  };

  const changeMode = () => {
    dispatch({ type: 'MODE_CHANGING' });
    dispatch({ type: 'MODE_TOGGLE' });
    setTimeout(() => {
      dispatch({ type: 'MODE_CHANGE_COMPLETE' });
    }, 800);
  };

  const toggleMode = (event) => {
    event.preventDefault();

    if (mode.includes('night')) {
      localStorage[MODE] = false;
    } else {
      localStorage[MODE] = true;
    }

    changeMode();
  };

  const headerClass = classnames(
    scrollState,
    {
      'open': isNavOpen,
    },
  );

  // Set Mode Icon
  let modeIcon;
  let tooltipLabel;
  if (mode.includes('night')) {
    modeIcon = 'brightness_3';
    tooltipLabel = 'Switch to day mode';
  } else {
    modeIcon = 'brightness_7';
    tooltipLabel = 'Switch to night mode';
  }

  return (
    <header className={headerClass}>
      <div className="header-container">
        <div className="section-container row">
          <div className="header-brand">
            <Link to="/" className="brand-symbol">
              <img
                src="assets/iteam-consulting-logo-symbol__brand.svg"
                alt="iTEAM Consulting Brand Logo"
              />
            </Link>
            <Link to="/" className="brand-type">
              {siteTitle}
            </Link>
          </div>
          <Links menu="AppBar" />
          <Spacer />
          <div className="header-actions">
            <Tooltip
              className="mode-switcher"
              label={tooltipLabel}
              style={{
                display: 'flex',
              }}
            >
              <IconButton
                onClick={toggleMode}
                name={modeIcon}
              />
            </Tooltip>
            <a
              onClick={toggleNav}
              className="nav-toggle"
            >
              <span className="burger-container">
                <span className="burger top" />
                <span className="burger middle" />
                <span className="burger bottom" />
              </span>
            </a>
            <a href="https://cw.iteamnm.com/v4_6_release/services/system_io/customerportal/portal.html?company=iteamnm&locale=en#LoginPagePlace:LOGOUT" target="_blank" rel="noopener noreferrer">
              <Button className="customer-portal">Customer Portal</Button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

AppBar.propTypes = {
  mode: PropTypes.string,
  dispatch: PropTypes.func,
  siteTitle: PropTypes.string,
  scrollState: PropTypes.string,
  isNavOpen: PropTypes.bool,
};

export default withRouter(connect((state) => state)(AppBar));
