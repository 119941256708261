import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import 'string.prototype.startswith';
import 'string.prototype.includes';

import React from 'react';
import ReactDOM from 'react-dom';
import configureStore, { history } from './store';

import Root from './containers/Root';
import data from './database/en-us.json';
import './index.css';

if (typeof (data.$schema) !== 'undefined') {
  delete data.$schema;
}

history.listen((location, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0);
  }
});

const store = configureStore(data);
let lastScrollPosition = getWindowScrollPosition();

window.onscroll = handleScroll;

/**
 * Gets the current scroll position
 * @return {number} The scroll Y position.
 */
function getWindowScrollPosition() {
  return window.scrollY || window.pageYOffset;
}

/**
 * Handle the scrolling event.
 * @param {object} e Event
 */
function handleScroll(e) {
  const { dispatch, getState } = store;
  const { scrollState } = getState();
  const currenScrollPosition = getWindowScrollPosition();
  const scrollingDown = lastScrollPosition < currenScrollPosition;

  if (scrollingDown && scrollState !== 'down') {
    dispatch({
      type: 'SCROLLING_DOWN',
    });
  } else if (lastScrollPosition === 0 && scrollState !== 'top') {
    dispatch({
      type: 'SCROLLING_TOP',
    });
  } else if (!scrollingDown && scrollState !== 'up') {
    dispatch({
      type: 'SCROLLING_UP',
    });
  }

  lastScrollPosition = window.scrollY || window.pageYOffset;
}

ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById('root')
);
