import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { ImageViewer } from '../';
import './Gallery.css';

const Gallery = ({ images, title }) => {
  const [imageContainer, setImageContainer] = useState(null);

  const unmount = () => ReactDOM.unmountComponentAtNode(imageContainer);

  useEffect(() => {
    setImageContainer(document.getElementById('image-container'));
    return unmount;
  }, []);

  const onClickImage = (index) => () => {
    ReactDOM.render(
      <ImageViewer
        selected={index}
        images={images}
        onClose={unmount}
      />,
      imageContainer
    );
  };

  return (
    <section id='gallery-section'>
      <div className="section-container">
        <h2 className='heading'>{title}</h2>
        <div className='gallery'>
          {images.map(({ src, title: imageTitle }, i) => (
            <div
              key={`image${i}`}
              className='image-card'
              title={imageTitle}
              onClick={onClickImage(i)}
            >
              <div
                className='image'
                style={{ backgroundImage: `url(${src})` }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    src: PropTypes.string,
  })).isRequired,
  title: PropTypes.string,
};

export default Gallery;
