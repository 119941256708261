import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardText } from 'react-mdl';
import { Action } from '../';
import './CardBar.css';

const CardBar = ({ cardBar: { cards } }) => {
  const cardList = cards.map((card, index) => {
    const { action, content, imageUrl } = card;

    const contentStyle = {};
    let contentClass = 'card-content';
    if (typeof imageUrl !== 'undefined') {
      contentStyle.backgroundImage = `url(${imageUrl})`;
      contentClass += ' with-image';
    }

    let cardAction = null;
    if (typeof action !== 'undefined') {
      cardAction = (
        <CardActions border className="card-action">
          <Action data={action} />
        </CardActions>
      );
    }

    return (
      <div key={index} className="card-container">
        <Card shadow={3} className="card">
          <CardText className={contentClass} style={contentStyle}>
            {content && <span>{content}</span>}
          </CardText>
          {cardAction}
        </Card>
      </div>
    );
  });

  return (
    <div className="card-bar">
      <div className="card-bar-container">
        {cardList}
      </div>
    </div>
  );
};

CardBar.propTypes = {
  cardBar: PropTypes.shape({
    cards: PropTypes.array.isRequired,
  }).isRequired,
};

export default CardBar;
