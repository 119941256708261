/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'react-mdl';
import './ImageViewer.css';

const ImageViewer = ({ onClose, images, selected }) => {
  const [selectedIndex, selectIndex] = useState(selected);

  // Function to change selected image
  const move = (delta) => {
    // Index constants
    const first = 0;
    const current = selectedIndex;
    const last = images.length - 1;

    // Calculate next image index
    const newImage = current + delta;

    // Clamp the next image index
    if (newImage < first) {
      selectIndex(last);
    } else if (newImage > last) {
      selectIndex(first);
    } else {
      selectIndex(newImage);
    }
  };

  // KeyUp Handler
  const handleKeyUp = (event) => {
    if (event.key === 'Escape') {
      onClose();
    } else if (event.key === 'ArrowLeft') {
      move(-1);
    } else if (event.key === 'ArrowRight') {
      move(1);
    }
  };

  // Add and remove keyup event listeners
  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [selectedIndex]);

  const { src, title } = images[selectedIndex];
  return (
    <div className="selected-image">
      <IconButton
        className="close"
        onClick={onClose}
        name="close"
      />
      <IconButton
        className="next"
        onClick={() => move(1)}
        name="chevron_right"
      />
      <IconButton
        className="prev"
        onClick={() => move(-1)}
        name="chevron_left"
      />
      <div className="image-container">
        <img src={src} alt={title} />
      </div>
    </div>
  );
};

ImageViewer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    src: PropTypes.string.isRequired,
  })).isRequired,
  selected: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageViewer;
