import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createRootReducer } from '../reducers';
// No DevTools

export const history = createBrowserHistory();

const configureStore = (preloadedState) => createStore(
  createRootReducer(history),
  preloadedState,
  compose(
    applyMiddleware(
      routerMiddleware(history)
    ),
    // No DevTools
  ),
);

export default configureStore;
