import React from 'react';
import PropTypes from 'prop-types';
import './Paragraph.css';

const Paragraph = ({ heading, copy, align }) => (
  <div className="paragraph">
    {heading ? <h5 className="paragraph-heading">{heading}</h5> : null}
    <p
      style={{
        textAlign: align || 'left',
      }}
    >{copy}</p>
  </div>
);

Paragraph.propTypes = {
  heading: PropTypes.string,
  copy: PropTypes.string.isRequired,
  align: PropTypes.string,
};

export default Paragraph;
