import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Paragraph, CardBar, List, Matrix, listPropType } from '../';
import BaseMap from './BaseMap';
import './Section.css';

const SectionComponent = ({
  odd,
  order,
  data: {
    p,
    visualization,
    list,
    cardBar,
    matrix,
    heading,
    subheading,
    list: dataList,
  },
}) => {
  const copy = (
    <div className="flex copy">
      <h2 className="heading">{heading}</h2>
      <h3 className="subheading">{subheading}</h3>
      {p && p.length > 0 && p.map((p, i) => <Paragraph key={i} {...p} />)}
      {list && <List list={dataList} />}
      {matrix && <Matrix {...matrix} />}
    </div>
  );

  // Default section content is the copy
  let contents = copy;

  // If a visualization exists, then change content of section
  if (typeof visualization !== 'undefined') {
    let containerClass = 'split-pane';
    if (visualization.position === 'left') {
      containerClass += ' reverse';
    }
    // Determine which type of visualization it is
    let visualizationContent;
    switch (visualization.type) {
      case 'image':
        visualizationContent = (
          <div
            className="visualization-image"
            style={{
              backgroundImage: `url(${visualization.imageUrl})`,
            }}
          />
        );
        break;
      case 'map':
        visualizationContent = <BaseMap {...visualization.map} />;
        break;
      default:
        break;
    }

    contents = (
      <div className={containerClass}>
        {copy}
        <div className="flex visualization">{visualizationContent}</div>
      </div>
    );
  }

  let cardBarWrapper = null;
  if (typeof cardBar !== 'undefined') {
    cardBarWrapper = <CardBar cardBar={cardBar} />;
  }

  return (
    <section
      className={odd ? 'odd' : 'even'}
      style={{ order: order }}
    >
      <div className="section-container">{contents}</div>
      {cardBarWrapper}
    </section>
  );
};

SectionComponent.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    matrix: PropTypes.object,
    subheading: PropTypes.string,
    p: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        copy: PropTypes.string.isRequired,
      })
    ),
    visualization: PropTypes.shape({
      type: PropTypes.oneOf(['image', 'map']).isRequired,
      imageUrl: PropTypes.string,
      map: PropTypes.shape({
        center: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        }),
        zoom: PropTypes.number,
        markers: PropTypes.arrayOf(
          PropTypes.shape({
            position: PropTypes.shape({
              lat: PropTypes.number.isRequired,
              lng: PropTypes.number.isRequired,
            }).isRequired,
            info: PropTypes.shape({
              label: PropTypes.string,
              title: PropTypes.string,
            }),
          })
        ),
      }),
      position: PropTypes.string,
    }),
    list: PropTypes.oneOfType([listPropType, PropTypes.arrayOf(listPropType)]),
    cardBar: PropTypes.shape({
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          imageUrl: PropTypes.string,
          action: PropTypes.shape({
            caption: PropTypes.string,
            url: PropTypes.string,
          }),
        })
      ),
    }),
  }).isRequired,
  odd: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
};

export default withRouter(SectionComponent);
