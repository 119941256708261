import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from 'react-mdl';

import './Matrix.css';
const Matrix = ({ tiers: headers, data }) => (
  <table className="matrix" cellPadding={0} cellSpacing={0}>
    <thead className="matrix-header">
      <tr>
        <th />
        {headers.map((t) => (
          <th key={t} className="matrix-header__tier">{t}</th>
        ))}
      </tr>
    </thead>
    <tbody className="matrix-body">
      {data.map(({ caption, tiers }) => (
        <tr key={caption}>
          <td className="matrix-body__caption">{caption}</td>
          {headers.map((t) => (
            <td key={caption + t} className="matrix-body__tier">
              {_.includes(tiers, t) && <Icon name="check" />}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

Matrix.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    caption: PropTypes.string.isRequired,
    tiers: PropTypes.arrayOf(PropTypes.string),
  })),
};

export default Matrix;
