import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Banner, CallToAction, Gallery, Section } from '../';
import { App } from '../../containers';

const Page = ({ data, children, location }) => {
  let content = children;
  if (!children) {
    const { sections, banner, callToAction, gallery } = data;
    if (!gallery) {
      const sectionList = sections.map((s, i) => {
        return <Section key={i} order={i} data={s} odd={i % 2 !== 0} />;
      });
      content = [];
      content.push(<Banner key="banner" data={banner} />);
      content.push(sectionList);
      content.push(<CallToAction key="call-to-action" data={callToAction} />);
    } else {
      content = <Gallery title={gallery.title} images={gallery.images} />;
    }
  }

  return (
    <App
      style={{ display: 'flex', flexDirection: 'column' }}
      id={_.kebabCase(location.pathname) || 'home'}
    >
      {content}
    </App>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export const pageFactory = (data) => withRouter(
  connect(({ scrollState, ...state }) => ({ data, ...state }))(Page)
);
