import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './ModeTransition.css';

const ModeTransition = ({ mode }) => (
  <div className={`mode-transition ${mode}`} />
);

ModeTransition.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default withRouter(connect((state) => ({
  mode: state.mode,
}))(ModeTransition));
