import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../config';
import PropTypes from 'prop-types';

const BaseMap = ({ center, zoom, markers }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const onLoad = React.useCallback((map) => {
    map.setCenter(center);
    map.setZoom(zoom);
  }, [center, zoom]);

  const markersComponents = markers.map((marker, index) => (
    <Marker {...marker} key={index} />
  ));

  return isLoaded ? (
    <GoogleMap
      zoom={zoom}
      center={center}
      mapContainerClassName= 'visualization-image map'
      onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      {markersComponents}
    </GoogleMap>
  ) : (
    <div>An error occurred while loading the map...</div>
  );
};

BaseMap.propTypes = {
  zoom: PropTypes.number,
  center: PropTypes.object,
  markers: PropTypes.array,
};

export default React.memo(BaseMap);
