import React from 'react';
import PropTypes from 'prop-types';
import './Banner.css';

const Banner = ({ data }) => {
  if (typeof data === 'undefined') {
    return null;
  }

  const { imageUrl, content } = data;
  return (
    <figure
      className="banner"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      {content && <span>{content}</span>}
    </figure>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    content: PropTypes.string,
  }),
};

export default Banner;
