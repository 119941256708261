import React from 'react';
import PropTypes from 'prop-types';
import {
  Footer,
  FooterSection,
  FooterDropDownSection,
  FooterLinkList,
  Spacer,
} from 'react-mdl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Links } from '../';
import './AppFooter.css';

const appFooter = ({ copyright, pages, siteTitle }) => {
  return (
    <Footer size="mega">
      <div className="section-container no-flex">
        <FooterSection type="middle">
          <FooterDropDownSection title="Offerings">
            <Links footer menu="Offerings" />
          </FooterDropDownSection>
          <FooterDropDownSection title="Company">
            <Links footer menu="Company" />
          </FooterDropDownSection>
          <FooterDropDownSection title="Social">
            <FooterLinkList>
              <a
                className="social-link"
                href="https://www.facebook.com/ITEAMNM/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/social/facebook.svg" alt="Facebook" />
                <span>Facebook</span>
              </a>
              <a
                className="social-link"
                href="https://x.com/iTEAMNM"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/social/x.png" alt="X" />
                <span>X (formerly Twitter)</span>
              </a>
            </FooterLinkList>
          </FooterDropDownSection>
          <FooterDropDownSection title="">
            <img
              alt="Sage 100 Contractor | Certified Consultant 2020"
              className="sage-partner"
              src="/assets/sage-contractor-2020.png"
            />
            <img
              alt="Sonicwall SecureFirst | Gold"
              className="other-partner"
              src="/assets/sonicwall-gold.png"
            />
            <img
              alt="Microsoft Partner"
              className="other-partner"
              src="/assets/microsoft-partner.png"
            />
          </FooterDropDownSection>
        </FooterSection>
        <FooterSection type="bottom">
          <span className="brand">
            <img
              src="assets/iteam-consulting-logo-symbol__brand.svg"
              alt="iTEAM Consulting Brand Logo"
              className="logo"
            />
            <span className="title">{siteTitle}</span>
          </span>
          <Links menu="Legal" />
          <Spacer />
          <span className="copyright">{copyright}</span>
        </FooterSection>
      </div>
    </Footer>
  );
};

appFooter.propTypes = {
  copyright: PropTypes.any,
  pages: PropTypes.any,
  siteTitle: PropTypes.string,
};

export default withRouter(connect((store) => store)(appFooter));
